#scrollUp {
    z-index: 999;
position: fixed;
bottom : 30px;
right: 20px;
opacity: 1;
//background-color: rgb(228, 228, 228);
background: rgb(38, 0, 255);
//background: linear-gradient(105deg,rgba(114, 33, 33, 0.8),rgba(212, 71, 71, 0.8), rgba(156, 120, 120, 0.8) );
height: 50px;
width: 50px;
border-radius: 50%;
color: rgb(240, 240, 240);
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;

border: 3px solid rgb(195, 193, 197);

i {
    font-size: 30px;
}

&:hover {
  
    border: 3px solid white;
}
}


