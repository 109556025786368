nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 2% 0 2%;
    background: linear-gradient(105deg,rgba(34, 33, 114, 0.6),rgba(71, 104, 212, 0.6), rgba(120, 125, 156, 0.6) );

    a {
       display: grid;
        text-decoration: none;
        color: black;
        padding: 8px;
        margin: 5px;
        font-size: 22px;
        .borderNav {
            margin-top: 5px;
            height: 2px;
            width: 100%;
            background-color: white;
            transition: transform 0.6s;
            transform: scaleX(0);
        }
        &:hover {
            color:rgb(253, 253, 253);
           .borderNav {
            transform: scaleX(100%);
            transition: transform 0.6s;
           }
    }
    }
    #accueil, #pays, #activity, #adhesion, #contact, #societes, #membre, #commande {
        padding-bottom: 30px;
        &:hover {
            #i {
              animation-duration: 1s;
              transform: translateY(6px);
            }
        }
       #i{
        font-size: 16px;
        margin-left: 8px;
        position: relative;
        transform: translateY(0px);
        transition: transform 0.4s;
        bottom: 6px;
       }
    }
  
}
.logoNav {
    display: flex;
}

@keyframes bottom {
    0% {

    }
    100% {
        bottom: 0;
    }
}

#navPrincipal {
    display: flex;
    align-items: center;
    position: relative;
    top: 10px;
}

#logo {
    position: relative;
    bottom: 15px;
    width: 50px;
    margin-right: 50px;
}
#logoS {
    display: none;
}

.menuHome {
    display: none;
    flex-direction: column;
    position: absolute;
   z-index: 5;
    background-color: rgb(184, 178, 178);
    border-radius: 10px;
    padding: 0 20px 0 20px;
    border: 2px solid white;
 a {
    border-bottom: 2px solid white;
 
    &:hover{
       
        color: rgb(250, 250, 250);
    }
 }
}
.accueilV:hover { 
#aaa {
    display: flex;
}
}
.paysV:hover {
    #bbb {
        display: flex;

    }
    }
.activityV:hover {
    #ccc {
        display: flex;

    }
    }  

.nav-active {
    color: rgb(255, 255, 255);
}
.nav-activee {
    color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 5px;
}

.nav-nonactive {
    border: 2px solid rgb(3, 3, 3);
    border-radius: 5px;
    &:hover {
        color: rgb(255, 255, 255);
        border: 2px solid rgb(255, 255, 255);
    }
}

#parametre {
    display: flex;
    justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: rgb(2, 136, 42);
      color: rgb(255, 254, 254);
      transition: 0.3s ease-in-out;
      text-align: center;
      border: 1px solid transparent;
      i {
          font-size: 18px; 
      }
      &:hover {
          box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
          color: white;
          background-color: rgb(4, 189, 65);
          border: 1px solid white;
      }
}
#member{
    display: flex;
    justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: rgb(120, 2, 136);
      color: rgb(255, 254, 254);
      transition: 0.3s ease-in-out;
      text-align: center;
      border: 1px solid transparent;
      i {
          font-size: 18px;
      }
      &:hover {  
          box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
          color: white;
          background-color: rgb(158, 4, 189);
          border: 1px solid white;
      }
}
#admin {
    position: relative;
    right: 8px;
     display: flex;
    justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: rgb(121, 99, 1);
      color: rgb(255, 254, 254);
      transition: 0.3s ease-in-out;
      text-align: center;
      border: 1px solid transparent;
      i {
          font-size: 18px;  
      }
      &:hover {       
          box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
          color: white;
          background-color: rgb(206, 186, 4);
          border: 1px solid white;
      }
}
///// Connexion ///
.navCo {
    padding-bottom: 30px;
    display: grid;
   position: absolute;
   right: 30px;
    justify-items: flex-end;
   #navSignup {
    #closeSign {
        background-color: rgb(168, 28, 3);
       }
    i {
     
       display: flex;
       padding: 8px;
       justify-content: center;
         align-items: center;
         border-radius: 50%;
         width: 30px;
         height: 30px;
         background-color: rgb(2, 44, 136);
         color: rgb(255, 254, 254);
         transition: 0.3s ease-in-out;
         text-align: center;
         border: 1px solid transparent;
        font-size: 18px;
        &:hover {
       
            box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
            color: white;
            background-color: rgb(2, 74, 230);
            border: 1px solid white;
        }
    }
  
   }
 
    #navLogin {
        position: relative;
        top: 10px;
       padding-bottom: 5px;
     
       &:hover {
        color: rgb(255, 255, 255);
       }
    }
}

#deco {
    display: flex;
    align-items: center;
    font-size: 20px;
    transition: 0.3s ease-in-out;
    i {
        color: red;
        margin-right: 5px;
        transition: 0.4s;
    }
    &:hover {
        color: red;
        i {
            transform: translateX(-10px);
        }
    }
}

.navS {
    display: none;}

.toggleNav {
    display: none;
    i{
        width: 70px;
        padding: 0;
    }
}

@media screen and (max-width: 1050px) {
    nav {
        .avatar {
            margin: 0;
           position: fixed;
           left: 0;
           right: 0;
           top: 80px;
          width: 100%;
          z-index: 999;
          img{
            height: 60px;
            width: 60px;
          }
          #bonjour{
           width: 100%;
           border-radius: 0;
           position: absolute;
           top: 12px;
           left: 0;
           right: 0;
           margin: 0;
           padding: 0;
           z-index: 999;
          }

          .avatarArrow {
            position: fixed;
            top: 10px;
            right: 12px;
           #arrow{
            display: none;
           }
        }
        }
      
     
    }
        
}

@media screen and (max-width: 1050px) {
    nav {
        a {
            font-size: 18px;
        }
       align-items: flex-start;
        #logo {
            width: 50px;
            margin-right: 0px; 
        }
        .navCo {
          // display: flex;
         //  flex-shrink: 0;
            padding: 0;
            margin: 0 0 20px 0;
           
        }
        i {
            //margin: 0 30px 0 20px;
            font-size: 30px;
            padding: 10px;
            &:hover {
                cursor: pointer;
            }
        }
       
      .navS {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        z-index: 999;
    
      }
      #navPrincipal {
       //display: grid;
       //row-gap: 10px;
       display: none;
      
       a {
        padding: 0;
        margin: 0;
        border: none;
       }
     /*  #activityV {
        margin-bottom: 8px;
      }*/
      }

      #logo {
        display: none;
      }

 
      .logoS {
        display: flex;
        width: 50px;
       
        margin-right: 20px;
          }

          .menuHome {
            margin-top: 0;
            padding: 20px;
            z-index: 5;
            background-color: rgb(184, 178, 178);
          }

        
     
    }
    .toggleNav {
        display: grid;
    }
    .borderNav {
        display: none;
    }

    .navColHide{
        z-index: 998;
        position: absolute;
        left:0;
        top: 92px;
        animation: .2s hideNavCol forwards;
        background: rgb(196, 193, 193);
        border-bottom: 2px solid white;
         display: grid;
        width: 100%;
        gap: 10px;
     
        span{
          //  left: 30px;
        }
    }
    .navColShow{
        z-index: 998;
        position: absolute;
        width: 100%;
        left: 0;
        top: 92px;
        background: rgb(196, 193, 193);
        border-bottom: 2px solid white;
         display: grid;
         animation: 1s showNavCol forwards;
        gap: 10px;
     
       .accueilV, .activityV, a#adhesion, .paysV, a#contact{
         
            position: relative;
          
            border-bottom: 1px solid white;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            span{
                left: 30px;
            }
       }     
    }

    .navColStart {
        opacity: 0;
        transform: scale(0);
        position: absolute;
      
    }
    nav  {

        #accueil, #activity, #adhesion, #pays, #contact {
            margin: 0;
            padding: 0;
            margin-left: 10px;
        }

        #accueil, #activity, #adhesion, #contact  {
width: 150px;
        }


        #adhesion {
         
            align-items: center;
        }
    
    }
   
    
    @keyframes showNavCol {
        0% {
           // transform: translateX(-220px);
            transform: scaleY(0);
            transform-origin: top;

        }
        100% {
           // transform: translateX(0px);
            transform: scaleY(1);
            transform-origin: top;
        }
    }
    @keyframes hideNavCol {
        0% {
           // transform: translateX(0);
            transform: scaleY(1);
            transform-origin: top;
        }
        100% {
           // transform: translateX(-300px);
            transform: scaleY(0);
            transform-origin: top;
        }
    }
    .paysV:hover {
        position: relative;
        #bbb {
            display: flex;
            position: absolute;
            top: 30px;
           left: 100px;
            animation: 1s showxxx ;
            background: rgb(2, 116, 182);
        }
        }
    .activityV:hover {
        position: relative;
       
        #ccc {
            animation: 1s showxxx ;
            display: flex;
            display: flex;
            position: absolute;
            top: 30px;
            left: 100px;
            background: rgb(2, 116, 182);
        }
        }  
        @keyframes showxxx {
            0% {
                    opacity: 0;
                    transform: scaleX(0);
                    transform-origin: left;
            }
            100% {
                    opacity: 1;
                    transform: scaleX(1);
                    transform-origin: left;
            }
        }
}


