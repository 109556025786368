#terre{
     h3{
        text-align: center;
        margin-bottom: 20px;
        font-size:26px;
        margin-top: 40px;
    }

   /* h2 {
        text-align: center;
        font-size:32px;
        padding: 10px;
        border-bottom: 2px solid white;
        margin-bottom: 40px;
    }*/

    h2 {
        margin-top: 120px;
        text-align: center;
        padding: 20px;
        font-size: 40px;
        margin-bottom: 50px;
        border-radius: 15px;
    
        background: linear-gradient(105deg,rgba(114, 33, 33, 0.8),rgba(212, 71, 71, 0.8), rgba(156, 120, 120, 0.8) );
        color: white;
        box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
        margin-left: 8%;
        margin-right: 8%;

    }

    p {
//margin-bottom: 40px;
        font-size:22px;
    }
      
}

.terreWurt, .terreMarq, .terreProf, .terreAud {
   // animation: 1.5s showCard forwards ease-in-out;
    margin-left: 8%;
    margin-right: 8%;
   // margin-top: 100px;
    border-radius: 15px;
    padding: 50px;
    background: rgba(252, 251, 251, 0.8);
    box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 880px) {

    #terre {

        h2{
            font-size: 30px;
            margin: 0;
            border-radius: 0;
        }
       
       
        h3 {
            font-size: 20px;
        }
        p {
            font-size: 18px;
            text-align: justify;
        }
      
    }
    .terreWurt, .terreMarq, .terreProf, .terreAud {
        // animation: 1.5s showCard forwards ease-in-out;
         margin-left: 0;
         margin-right: 0;
         margin-top: 0;
         border-radius: 0;
         padding: 30px;
       
         background: rgba(252, 251, 251, 0.8);
     }
}