

#navActivity, #navPays {
    z-index: 5;
    position: fixed;
    top : 0;
    opacity: 1;
    background: rgb(2, 101, 194);
   // background: linear-gradient(105deg,rgba(114, 33, 33, 1),rgba(212, 71, 71, 1), rgba(156, 120, 120, 1) );
  //  padding: 20px 0 20px 0;
    width: 100%;
    color: rgb(240, 240, 240);
    display: flex;
    justify-content:space-between ;
    align-items: center;
    font-weight: 600;

    span {
        margin-left: 50px;
        padding: 20px;
        cursor: pointer;
        z-index: 6;
        font-size: 30px;
    }
    span:hover{
        #ddd{
            
            display: flex;
        }
        #bbb {
            display: flex;
        }

        .borderActiv {
           // transform: translateX(100%);
            transform: scaleX(100%);
            transition: transform 0.6s;
        }

    }
    img {
        width: 50px;
        margin-right: 8%;
    }
}

.ddd, .bbb {
    display: none;
    flex-direction: column;
    //position: fixed;
    position: absolute;
    z-index: 5;
    margin-top: 10px;
  
    background-color: rgb(184, 178, 178);
    border-radius: 10px;
    padding: 0 20px 0 20px;
    border: 2px solid white;
 a {
    text-decoration: none;
    color: black;
    border-bottom: 2px solid white;
    font-size: 20px;
    margin-bottom: 10px;
 
    &:hover{
       
        color: rgb(250, 250, 250);
    }
 }
}

#pActiv, #pPays {
   // padding-bottom: 30px;
   .borderActiv {
    height: 2px;
    width: 100%;
  //  background: transparent;
  background: white;
  transition: transform 0.6s;
  transform: scaleX(0);
   }
  
   i {
    font-size: 20px;
    margin-left: 8px;
    position: relative;
    transform: translateY(0px);
    transition: transform 0.4s;
    bottom: 6px;
   }
   &:hover {
   
  
    i {
     
        transform: translateY(6px);
      }
   }

}