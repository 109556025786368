.contact {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 100px;
    .infos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
     justify-content: center;
      margin-bottom: 10px;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background:   rgba(231, 233, 236, 0.8);
        box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
        padding: 25px;
        border-radius: 15px;
    }
  
    input {
        padding: 15px;
        border-radius: 10px;
    }
    #sujet {
        margin-bottom: 10px;
    }
    #area {
        display: flex;
    width: 100%;
    margin-bottom: 10px;
       
    }
    textarea {
        width: 100%;
        border-radius: 10px;
        padding: 20px;
    }

    iframe {
     //   margin-left: 50px;
        width: 100%;
        border-radius: 15px;
        box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
    }
    .map {
       // display: flex;
        width: 100%;
      margin-left: 20px;
    }
}

@media screen and (max-width: 1200px) {

  .contact {
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;

    form {
        width: 100%;
        padding: 0;
     
        border-radius: 0;
        textarea{
            width: 70%;
            margin: auto;
            margin-top: 15px;
        }
    }

    .infos{
        width: 70%;
        margin: auto;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    #sujet {
        width: 70%;
      
        margin: auto;
    }

    .map {
        display: flex;
        justify-content: center;
        background:   rgba(231, 233, 236, 0.6);
        padding-bottom: 30px;
        margin: 0;
       
    }
    iframe {
        margin: 0;
        width: 100%;
        border-radius: 0;
    }
  }
    
}

@media screen and (max-width: 530px) {


    .contact .infos {
       grid-template-columns: 1fr;
    }
    .map {
        width: 100%;
        background:   rgba(231, 233, 236, 0.8);
    }
}




