header {  
   // margin-bottom: 30px;
   
    h1 {
        font-size: 120px;
        margin-right: 20px;       
        color: rgb(180, 3, 3);
        animation: 1.5s titleOne forwards ease-in-out;
    }
    h2 {
        
        animation: 1.5s titleTwo forwards ease-in-out;
    }
}

.title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 150px 100px 100px 0;
  
    
}

@keyframes titleOne {
        0% {
            opacity: 0;
            transform: translate3d(-250px, -250px, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
}

@keyframes titleTwo {
    0% {
        opacity: 0;
        transform: translateY(-250px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}



.subTitle {
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    margin: 0 10% 0 0;
    #archeologie {
        color: rgba(117, 0, 0, 0.5);
    }
    #histoire {
        color: rgba(117, 0, 0, 0.5);
    }
    #lettres {
        color: rgba(117, 0, 0, 0.5);
    }
    #art {
        color: rgba(117, 0, 0, 0.5);
    }
    #sciences {
        color: rgba(117, 0, 0, 0.5);
    }
}

.headerTwo {
    display: flex;
    align-items: center;
    padding: 30px 30px 0 30px;
   // background: linear-gradient(105deg,rgba(114, 33, 33, 0.6),rgba(212, 71, 71, 0.6), rgba(156, 120, 120, 0.6) );
   background: linear-gradient(105deg,rgba(34, 33, 114, 0.6),rgba(71, 104, 212, 0.6), rgba(120, 125, 156, 0.6) );

    .back {
        z-index: 555;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        text-decoration: none;
        background-color: rgb(1, 53, 165);
        border: 4px solid transparent;
        margin-right: 30px;
       position: relative;
       right: 20px;
       bottom: 10px;
       
       i {
        font-size: 20px;
        color: white;
       }
        &:hover {
            background-color: rgb(255, 255, 255);
            border: 4px solid rgb(16, 174, 236);
            i {
               animation: 0.4s rebond;
               color: rgb(66, 77, 236);
            }
        }
    }
    #logoTwo {
        width: 60px;
        margin: 0 20px 0 20px;
    }
    span {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center ;
        justify-content: center;
        font-size: 40px;
        color: white;
        width: 80%;
        text-align: center;
        position: relative;
        
    }
    a {
        text-decoration: none;
    }
}

@keyframes rebond {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(5px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}


@media screen and (max-width: 1050px) {
    header {
        margin-bottom: 0;
    }
    .title {
      
        display: grid;
       grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        border-top: 2px solid red;
        justify-items: center;
        padding: 20px 50px 20px 50px;
       // justify-content: center;
        margin:0;
        background: rgba(252, 251, 251, 0.8);
        h1 {
            font-size: 60px;
            margin-right: 0;
        }
        h2 {
            font-size: 20px;
        }

    }
    .subTitle {
        display: grid;
       grid-template-columns: 1fr 1fr 1fr ;
       grid-template-rows: 1fr 1fr;
      justify-items: center;
       grid-area: 2 / 1 / 2 / 3;
       margin:0;

       #art {
        grid-area: 2 / 1 / 2 / 3;
        margin-top: 15px;
       }
       #sciences {
        grid-area: 2 / 2 / 2 / 4;
       }
    }
   
}

@media screen and (max-width: 1050px) {

    header {
        span{
            position: relative;
            right: 50px;
        }
    }
}



