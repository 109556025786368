.introStory {
    display: grid;
    grid-template-columns: 1fr ;
   
}
#administration {
   
    border: 2px solid white;
    border-radius: 10px;
    padding: 30px;
    background: rgba(252, 251, 251, 0.8);
}

.drapeauLogo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#intro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8%;
    margin-right: 8%;
    border: 2px solid white;
    border-radius: 10px;
    padding: 30px;
    background: rgba(252, 251, 251, 0.8);
    margin-bottom: 40px;
    color: rgb(3, 3, 3);
    box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
    h4 {
        text-align: center;
        font-size:32px;  
        padding-bottom: 10px;
        margin-top: 30px; 
        margin-bottom: 30px; 
        border-bottom: 2px solid white;     
    }
    p {
        font-size: 20px;
        font-weight: 600;
    }
    img {
        width: 60px;
        height: 60px;
    }
    .drapeau {
        display: flex;
      
        img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
            cursor: pointer;
            border: 3px solid transparent;
            border-radius: 50%;
            &:hover {
                border: 3px solid rgb(49, 191, 216);
            }
        }
       
    }
}

#administration, #historique {
    margin-right: 8%;
    margin-left: 8%;
   
    h2 {
      //  margin-top: 80px;
        text-align: center;
        padding: 20px;
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 20px;
        border-radius: 15px;
        background: linear-gradient(105deg,rgba(114, 33, 33, 0.8),rgba(212, 71, 71, 0.8), rgba(156, 120, 120, 0.8) );
        color: white;
        box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
        margin-left: 8%;
        margin-right: 8%;
    }
}


.general {
    margin-left: 8%;
    margin-right: 8%;
    border: 2px solid white;
    border-radius: 10px;
    padding: 20px;
    background: rgba(252, 251, 251, 0.8);
    margin-bottom: 40px;
    color: rgb(3, 3, 3);
    box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
    h4 {
        text-align: center;
        font-size: 28px;
        padding: 10px;
        border-bottom: 2px solid white;
        margin-bottom: 20px;
    
       }
       h5 {
        text-align: center;
        font-size: 23px;
        padding: 5px;
        border-bottom: 2px solid white;
        margin-bottom: 20px;
        margin-top: 20px;
       }
       h6 {
      
        font-size: 21px;
       // width: 70%;
        padding-bottom: 5px;
        margin-bottom: 8px;
        border-bottom: 1px solid red;
       }
    p {
        display: flex;
       flex-direction: column;
       align-items: flex-start;
      //  border: 1px solid rgb(129, 129, 129);
       // border-radius: 10px;
        font-size: 18px;
        padding: 10px;
        font-weight: 600;
        margin-bottom: 15px;
        
    }
   
}

.generalHistorique {
    margin:0;
    border: 2px solid white;
    border-radius: 10px;
    padding: 20px;
    background: rgba(252, 251, 251, 0.8);
    margin-bottom: 40px;
     color: rgb(3, 3, 3);
     box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
     p {
        font-size: 20px;
     }
}

#adminGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}

.generalA {
    display: grid;
    align-items: flex-start;
    border: 2px solid white;
    border-radius: 10px;
    padding: 20px;
    background: rgba(252, 251, 251, 0.8);
     color: rgb(3, 3, 3);
     box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
       h3 {
        text-align: center;
        font-size: 23px;
        padding: 5px;
        border-bottom: 2px solid rgb(255, 0, 0);
        margin-bottom: 20px;
        margin-top: 20px;
       }    
}


.presidence, .secretariat, .tresorerie, .assBibl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    span {
        margin-bottom: 30px;
    }
    h4 {
     
        font-size: 20px;
        padding-bottom: 5px;
        margin-bottom: 8px;
        border-bottom: 1px solid red;
       }
       p {
       text-align: center;
        margin-bottom: 15px;
       }   
}

.adminadmin {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    p {
        padding: 10px;
        margin: 5px 0 5px 0;
    }
}

@media screen and (max-width: 1500px) {

    #adminGrid {
        grid-template-columns: 1fr;
        .generalA {
            margin-bottom: 30px;
        } 
    }
 }

 @media screen and (max-width: 1050px) {

  #intro, #historique,.generalHistorique {
    margin: 0;
    border-radius: 0;
    
  }

  #intro, #historique {
    border-top: 2px solid red;
  }

    #administration {
        margin: 0;
        border-radius: 0;
        border-top: 2px solid red;
    }

    #adminGrid {
       
        h3 {
            font-size: 20px;
        }
        h4{
            font-size: 14px;
        }
    }

    .adminadmin {
       // align-items: flex-start;
        p {
            font-size: 12px;
            padding: 5px;
            margin: 5px 0 5px 0;
        }
    }
 }






