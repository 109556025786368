#headerPost {
    display: flex;
    justify-content:space-between;
    a {
        padding: 10px;
        margin:20px 20px 0 20px;
        text-decoration: none;
        color: black;
      font-size: 26px;
      border: 2px solid white;
    }
    #back {    
            &:hover {
                color:blue;
                border-bottom: 1px solid blue;
            }
    }
    #logout {         
            &:hover {
                color:red;
                border-bottom: 1px solid red;
            }
        }   
}

#menu-gestion {
   border-top: 2px solid white;
    width: 100%;
    background: linear-gradient(105deg,rgba(109, 109, 109, 0.6),rgba(74, 79, 97, 0.6), rgba(99, 99, 100, 0.6) );
    #avatar-theme{
        padding:20px;
    }
    button{
        margin: 20px;
        width: 150px;
        padding: 15px;
        border-radius: 10px;
        border: 3px solid white;
        background: rgb(255, 255, 255);
        color: rgb(3, 93, 177);
        box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
        transition: 0.2s;      
        font-size: 16px;
        cursor: pointer;
        &:hover{
            transition: 0.2s;
            background-color: rgb(22, 131, 204);
            background-color: rgb(22, 131, 204);
            color: white;
        }       
    }
    .btn-gestion-active{
        background-color: rgb(22, 131, 204);
        color: rgb(255, 255, 255);    
    }
}
#menu-gestion-secondaire{
   display: flex;
   justify-content: center;
   border-top: 2px solid white;
    button{
        margin: 20px 5px 20px 5px;
        width: 140px;
        padding: 6px;
        border-radius: 10px;
        border: 3px solid white;
        background: rgb(255, 255, 255);
        color: rgb(3, 93, 177);
        box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
        
        font-size: 16px;
        cursor: pointer;
        &:hover{
            transition: 0.2s;
            background-color: rgb(22, 131, 204);
            background-color: rgb(22, 131, 204);
            color: white;
        }
       
       
    }
    .btn-gestion-active{
        background-color: rgb(22, 131, 204);
        color: rgb(255, 255, 255);
    
    }

}
#separation{
    border-bottom: 2px solid white;
    margin-bottom: 20px;
}
#choixADCG {
    display: grid;
    width: 50%;
    min-width: 350px;
    margin: auto;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

    button{
        position: relative;
        margin: 0 0 20px 0;
        padding: 4px;
        border-radius: 10px;
        border: 3px solid white;
        background: rgb(255, 255, 255);
        color: rgb(3, 93, 177);
        box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
     width: 120px;
        cursor: pointer;
        &:hover{
            transition: 0.2s;
            background-color: rgb(22, 131, 204);
            color: white;
            span{
                color: rgb(255, 255, 255);
            }
        }
        span{
            position: absolute;
           right: -10px;
           top: -5px;
           height: 22px;
           width: 22px;
           align-items: center;
           display: flex;
           justify-content: center;
           font-weight: bolder;
          // z-index: 899;
           border: 2px solid white;
           background-color: rgb(22, 131, 204);
           color: rgb(255, 255, 255);
        
            border-radius: 50%;
          
        }
       
       
    }
    .btn-gestion-active{
        background-color: rgb(22, 131, 204);
      
    }
}


.changeTheme {
  select{
    padding: 6px;
    border-radius: 10px;
    cursor: pointer;
    color: rgb(3, 93, 177);
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  }
  margin-top: 20px;
}

.changePhoto, .changeTheme {
   display: flex;
   align-items: center;
 
    p{
        margin-right: 15px;
        color: white;
    }

   
}

#photoProfil {
   // width: 150px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   
        img {
            width: 70px;
            height: 70px;
           
            border: 2px solid white;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
            &:hover {
                border: 2px solid rgb(255, 255, 255);
               
                box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
            }
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 80px;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;
            margin-left: 20px;
           
        }
    
}

#fileProfil {
    display: none;
}

#zoneScroll {
    width: 50%;
    height: 300px;
    margin: auto;
    padding: 20px 20px 0 20px;
    background-color: grey;
    border-radius: 20px;
    color: white;
   span {
    margin-bottom: 20px;
   }
   
}
.spanBas {
    background-color: grey;
    position: relative;
    bottom: 40px;
    padding: 20px 20px 0 20px;
    width: 50%;
    margin: auto;
}
#bas {
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    bottom: 20px;
     border-radius: 10px;
     padding: 5px;
     background-color: rgb(7, 18, 165);
     width: 80px;
     margin: auto;
     cursor: pointer;
 }
 #haut {
     display: flex;
     justify-content: center;
   
      border-radius: 10px;
      padding: 5px;
      background-color: rgb(165, 44, 7);
      width: 80px;
      margin: auto;
      cursor: pointer;
 }
.no {
   overflow-y: hidden;
}

.yes {
    overflow-y: scroll;
}


#afficheCommande {

}

#ligneCommande {
    border-radius: 5px;
   
    align-items: center;
    background: rgb(38, 38, 185);
    color: white;
    padding: 20px;
    width: 50%;
    margin: auto;
    display: grid;
    grid-template-columns:0.6fr 1.2fr 0.4fr ;
    border-bottom: 1px solid white;
    button{
        border-radius: 5px;
        padding: 8px;
        cursor: pointer;
        &:hover{
            background-color: rgb(181, 241, 181);
        
        }
    }
    #mess-cmd-valide{
        box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        background-color: green;
        text-align: center;
        padding: 5px;
    }
}

@media screen and (max-width: 1050px) {
    #menu-gestion{
        flex-direction: column;
        width: 100%;
      
       
        .changePhoto, .changeTheme{
        
        }
      
    }
}

