

///////////////GESTION PDF CP GRAVURES //////////////////////


#gestion-pdf, #gestion-cp {
    padding: 0 15px 15px 15px ;
    border-radius: 5px;
   width: 40%;
   margin: auto;
   background: linear-gradient(105deg,rgba(34, 33, 114, 0.6),rgba(71, 104, 212, 0.6), rgba(120, 125, 156, 0.6) );
}

#askPdf, #askCP {
    display: flex;
    justify-content: center;
}
#askAddPdf, #askDeletePdf, #askAddCP, #askDeleteCP{
  margin: 15px;
   cursor: pointer;
   padding: 10px;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   border: 1px solid transparent;
   font-size: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
}
#askAddPdf, #askAddCP{
    border: 3px solid rgb(22, 245, 1);
}
#askDeletePdf, #askDeleteCP{
    border: 3px solid rgb(255, 0, 0);
}
.info-pdf, .info-cp {
    padding:0 10px  10px 0;
    input{
        padding: 5px;
        border-radius: 5px;
    }
    
}

#pdfprix{
    margin-right: 15px;
}

#typepdf {
    display: flex;
    align-items: center;
}
#pdftype {
   margin-left: 5px;
}

.pdftype{
    border: 1px solid white;
    background: rgb(128, 243, 138);
    display: flex;
   padding: 3px;
   border-radius: 5px;
}



#btn-addpdf, #btn-addcp {
    padding: 5px;
    margin-top: 10px;
}
#zone-btn-close-addpdf, #zone-btn-close-addcp{
    display: flex;
    justify-content: end;
    button {
        position: relative;
        top: 20px;
        border: 1px solid white;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        cursor: pointer;
        background-color: rgb(255, 71, 71);
        color: white;
        transition: 0.2s;
        &:hover {
            transition: 0.2s;
            background-color: rgb(243, 0, 0);
        }
    }
}

  //******************  AFFICHAGE PDF CP GRAVURES **************************//


#allpdf, #allcp {
max-width: 1200px;
    border-radius: 15px;
   // padding: 5px 3px 5px 3px;
    margin: auto;
    margin-top: 30px;
   
    a, p, span {
        text-decoration: none;
        color: white;
        width: 100%;
        text-align: center;
        padding: 10px 0 10px 0;
    }
    a{
        cursor: pointer;
        border-right: 1px solid white;
        border-left: 1px solid white;
        &:hover {
            background: linear-gradient(105deg,rgba(33, 114, 69, 0.8),rgba(71, 212, 170, 0.8), rgba(120, 156, 141, 0.8) );

        }
    }

    span {
        cursor: pointer;
        border-right: 1px solid white;
       
        &:hover {
            background: linear-gradient(105deg,rgba(33, 114, 69, 0.8),rgba(71, 212, 170, 0.8), rgba(120, 156, 141, 0.8) );

        }
    }
    #tableur-pdf {
        border-right: 1px solid white;
        border-left: 1px solid white;
    }
    img {
        position: fixed;
        top: 300px;
        left: 15%;
        width: 300px;
        height: 300px;
        border-radius: 20px;
       
    }
    #originale{
        opacity: 0.5;
    }
    #specimen{
        color: white;
        position: fixed;
        top: 300px;
        left: 15%;
        width: 300px;
        height: 300px;
        border-radius: 20px;
        background: rgb(114, 113, 113);
        overflow: hidden;
      p{
        text-align: left;
        text-wrap:nowrap;
        font-size: 20px;
      }
    }
    .specimen {
        transform: rotate(-45deg) translateX(-50px);
    
    }
  
}

/// ************ PDF GRATUITS ************ ///

#pdfgratuit{
    margin-bottom: 40px;
    background: linear-gradient(105deg,rgba(20, 77, 34, 0.8),rgba(36, 104, 62, 0.8), rgba(120, 156, 138, 0.8) );
}


/// ************ PDF PAYANTS ************ ///

#pdfpayant{
    background: grey;
}

#categorie-pdf, .pdfpayant, .pdfgratuit {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 0.1fr;
    border-bottom: 1px solid white;
}
#categorie-pdf{
    border-top: 1px solid white;
    background: linear-gradient(105deg,rgb(21, 20, 70),rgb(49, 71, 146), rgb(87, 91, 114) );

}
.pdfpayant{
    border-bottom: 1px solid white;
    #a{
        cursor: pointer;
        border-right: 1px solid white;
        border-left: 1px solid white;
        &:hover {
            background: linear-gradient(105deg,rgba(33, 114, 69, 0.8),rgba(71, 212, 170, 0.8), rgba(120, 156, 141, 0.8) );

        }
    }

    #b{
          color: rgb(71, 1, 71);
          border: none;
          background: transparent;
          &:hover {
            background: transparent;
          }
    }
    #c{
        color:  rgb(0, 13, 126);
        border: none;
        background: transparent;
        &:hover {
          background: transparent;
        }
    }
}

/// ************ PDF POSSEDES ************** ///
#categorie-pdf-possede {
   
    margin-top: 40px;
    background: linear-gradient(105deg,rgb(21, 20, 70),rgb(49, 71, 146), rgb(87, 91, 114) );
    border-bottom: 1px solid white;
}
#pdfpossede {
    
 
  
}

.lien-achete{
    display: flex;
    a#lien-pdf-possede{
        text-align: center;
        background: linear-gradient(105deg,rgb(121, 112, 32),rgb(122, 117, 36), rgb(194, 181, 0) );
        border-bottom: 1px solid white;
        margin: 0;
       padding: 15px;
       &:hover{
        background: linear-gradient(105deg,rgba(33, 114, 69, 0.8),rgba(71, 212, 170, 0.8), rgba(120, 156, 141, 0.8) );

       }
    
    }
}



/// ************** SUPPRIMER PDF ************ ///


#supprimerpdf {
    background: red;

}


/// ************ CP ************ ///

.cp{
    background-color: grey;
}
#categorie-cp, .cp{
    display: grid;
    grid-template-columns:1fr 0.3fr;
    border-bottom: 1px solid white;
    
}

#categorie-cp{
    border-top: 1px solid white;
    background: linear-gradient(105deg,rgb(21, 20, 70),rgb(49, 71, 146), rgb(87, 91, 114) );

}

#tableur-cp {
    border-right: 1px solid white;
   
}