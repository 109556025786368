footer {
    .footPosition {
        position: relative;
        margin-top: 150px;
        padding: 20px 0 20px 0;
       height: 200px;
       // background: linear-gradient(105deg,rgba(114, 33, 33, 0.6),rgba(212, 71, 71, 0.6), rgba(156, 120, 120, 0.6) );
       background: rgb(78, 78, 78);
       color: white;
        display: flex;
        justify-content: center;
       p{
       
        font-size: 16px;
        padding-top: 6px;
       }
        span {
            position: absolute;
            bottom: 10px;
            right: 40px;
           }
           .pFooter {
            display: flex;
            flex-direction: column;
            align-items: center;   
        }

           .reseau {
            display: flex;
            height: 40px;
        }
        a {
            display: block;
            text-decoration: none;
            color: white;
            font-size: 32px;
            margin-right: 10px;
            i {
                transition: 0.3s;
                border-radius: 50%;
                
            }
            &:hover {
           
                i {
                  //  border: 2px solid red;
                  color: rgb(0, 195, 255);
                }
               }         
        }
    }
}

@media screen and (max-width: 1050px) {
    footer {
        border-top: 2px solid red;
        .footPosition{
            margin-top: 0;
        }
    }
    }
   

