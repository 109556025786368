#imgModify {
   p {
    padding: 10px;
    background-color: rgb(216, 213, 213);
    margin-right: 10px;
    border-radius: 10px;
   }
    display: flex;
    align-items: center;
    margin-right: 20px;
    img {
        object-fit: cover;
        border-radius: 10px;
        height: 150px;
        width: 150px;
    }
}