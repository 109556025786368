
.socFC, .socBourgogne, .socAlsace, .socAutres {
    display: grid;

    margin-left: 8%;
    margin-right: 8%;
    border-radius: 15px;
    background: rgba(252, 251, 251, 0.8);
    margin-bottom: 50px;
    box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
    padding: 50px;
    h3 {
        font-size: 26px;
        margin-bottom: 20px;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 2px solid black;
    }
    a {
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        padding: 10px;
        color: black;
        &:hover {
            color: rgb(2, 118, 185);
        }
    }
}
