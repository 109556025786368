.activityContain {
    display: flex;
    flex-direction: column;
   
    margin-top: 100px;

    ul {
        display: flex;
        flex-direction: column-reverse;
      //  margin: 0 10% 0 10%;
        max-width: 1000px;
        width: 90%;
      
        margin: auto;
       // display: grid;
       // grid-template-columns: 1fr 1fr;
    }
    li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
        padding: 50px;
        margin-bottom: 50px;
        animation: 1.5s showCard forwards ease-in-out;
        // margin-left: 5%;
        // margin-right: 5%;
        border-radius: 15px;
        background: rgba(252, 251, 251, 0.8);
        margin-bottom: 50px;
        box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
        p {
            text-align:justify;
        }

        h1 {
            border-bottom: 1px solid rgb(21, 52, 110);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        h2 {
            margin-bottom: 10px;
            padding: 0;
            background: transparent;
        }
        .imgActivity {
            display: flex;
            justify-content: center;
            img {
                object-fit: cover;
                object-position: top;
                width: 50%;
              //  height: 500px;
                margin: auto;
                border-radius: 10px;
                
            }
        }
    }
}

.info {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    p{
        margin-left: 10px;
    }
}

@keyframes showCard {
    0% {
        opacity: 0;
       
    }
    100% {
           opacity: 1;
       
    }
}


.actionActivity {
    display: flex;
    justify-content:flex-end;
    margin-bottom: 20px;
    input {
        margin-left: 20px;
        padding: 10px;
        cursor: pointer;
       color: white;
     
    }
    .deletePost {
        color: red;
        border: 2px solid red;
        border-radius: 10px;
        background-color: rgb(230, 229, 229);
        &:hover {
            background-color: white;
          
        }
        
    }
    .modifyPost {
        color: blue;
        border: 2px solid blue;
        border-radius: 10px;
        background-color: rgb(230, 229, 229);
        &:hover {
            background-color: white;
        }
    }
}


.dataPost {
    margin-bottom: 20px;
}

.bottomPost {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}