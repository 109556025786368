#messageContact {
    h2{
        margin:auto;
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
        background-color: rgba($color: #0d0361, $alpha: 0.5);
        padding: 10px;
        border-radius: 10px;
        color: rgb(255, 255, 255);
        display: flex;
        justify-content: center;
        span{
            margin-left: 20px;
            border-radius: 50%;
            background: blue;
           height: 25px;
           width: 25px;
            display:flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }
}
#messageAdhesion {
    h2{
        margin:auto;
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
        background-color: rgba($color: #0d0361, $alpha: 0.5);
        padding: 10px;
        border-radius: 10px;
        background-color: rgba(91, 241, 91, 0.5);
    }
}

#messNL {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
   
    i{
        color: rgb(38, 0, 255);
        margin-right: 20px;
    }
}
.messNL{
    background: rgba($color: #93e59e, $alpha: 0.5);
}



.messageContactAdhesion {
    display: flex;
    flex-direction: column-reverse;
    
   
    
    h3{
        
        padding: 5px;
        
    }
    h4 {
        border-bottom: 2px solid black;
        margin-bottom: 8px;
        padding: 5px;
    }
    margin:auto;
    width: 50%;
    list-style-type: none;
   
   
    li {
        padding: 10px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 10px;

    }
}

.boxDelContactMessage {
    position: absolute;
    left: center;
    width: 200px;
    border-radius: 20px;
    padding: 10px;
    background-color:  rgb(235, 72, 72);
    text-align: center;
   display: grid;
   justify-items: center;
   p{
    padding: 6px;
    margin: 5px 5px 0 5px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: rgb(224, 220, 220);
    color: black;
    &:hover {
        border: 1px solid black;
        background-color: rgb(238, 234, 234);
        cursor: pointer;
    }
   }
}

.messageContact-deleteMessageContact {
//     display: flex;
//     justify-content: space-between;
//    overflow: auto;
 .infoMessageContact{
    width: 100%;

 }
 .infoMessageAdhesion{

    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
 }

 .adresseAdhesion{
    margin-top: 10px;
 }

  #messageContent {
   
    padding: 15px 30px 15px 30px;
  
    border-radius: 10px;
    background-color: antiquewhite;
    
   }

    button{
       
      
        cursor: pointer;
        margin: 20px 0 10px 20px;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid transparent;
        background-color: rgb(243, 135, 135);
        &:hover {
            background-color: rgb(245, 20, 20);
            border: 1px solid rgb(3, 3, 3);
            box-shadow: 2px 2px 2px rgb(95, 95, 95);
        }
    }
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    
  }
  *::-webkit-scrollbar-track {
   
  //  background: orange;
    border-radius: 5px;
  //  border: 5px solid RGB(108, 133, 211);
  }

  
  *::-webkit-scrollbar-thumb {
    background-color: rgb(27, 45, 148);
    border-radius: 5px;
   // border: 3px solid RGB(108, 133, 211);
  }

  #entete-contact {
    cursor: pointer;
  }