.editeurNew {
 margin: 0 8% 30px 8%;
padding-bottom: 100px;
   width: 90%;
   max-width: 900px;
   margin: auto;
   display: flex;
  flex-direction: column;
    justify-content: space-between;
  
}

.editor {
    padding-bottom: 50px;
}

#myTiny {
    margin-bottom: 20px;
    padding-bottom: 50px;
}


.fileImg {
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 100%;


    label {
        display: flex;
        align-items: center;
        position: relative;
        i {
            display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        font-size: 20px;
        background: rgb(143, 143, 221);
        padding: 20px;
        color: white;
        border-radius: 50%;
        border: 2px solid transparent;
            color: white;  
            &:hover {
                border: 2px solid white;
              
               }
        }
     span {
       
        
        display: none;
     }
     &:hover {
        span {
            flex-shrink: 0;
            position: absolute;
           // left: 60px;
          //  top: 80px;
          bottom: 60px;
            display: flex;
            justify-content: center;
            width: 150px;
   
            opacity: 1;
            color: rgb(0, 0, 0);
            background: rgb(209, 204, 204);
            border-radius: 10px;
            padding: 5px;
            border: 2px solid white;
        }
     }
      
    }

    select {
        
        margin-right: 20px;
        padding: 20px ;
        border-radius: 20px;
        text-align: center;
     
        
    }
    button {
        display: flex;
        align-items: center;
        height: 20px;
        padding: 20px;
        border-radius: 10px;
        cursor: pointer;
    }
}
#fileImg {
    display: none;
    opacity: 0;
}



