.user {
    max-width: 590px;
   margin: auto;
    border-radius: 20px;
    padding: 30px;
    background: linear-gradient(105deg,rgba(34, 33, 114, 1),rgba(71, 104, 212, 1), rgba(120, 125, 156, 1) );
    color: rgb(255, 255, 255);
    margin-top: 80px;
    h3 {
        text-align: center;       
    }
}
@media screen and (max-width: 800px) {
.user {
    max-width: 100%;
   // width: 100%;
    padding: 0;
    border-radius: 0;
    margin: 0;
    h3 {
        font-size: 25px;
        padding: 20px;
    }
    #ulUser{
        width: 100%;
        li{
            width: 100%;
            padding: 0;
        }
        .tableau-admin{
            grid-template-columns:0.4fr 0.8fr 1.3fr 0.3fr ;
            img{
                width: 40px;
                height: 40px;
                position: relative;
                left: 8px;
            }
            span{
                font-size: 14px;
            }
            i{
                font-size: 14px;
              
            }
        }
     
    }
}

}

.tableau-email-tel, .tableau-adresse, .tableau-name-firstname {
    display: grid;
}

#ulUser {
    display: grid;
    .tableau-admin{
        display: grid;
        grid-template-columns: 100px  150px  270px 60px;
        align-items: center;

        img{
           
            border-radius: 10px solid white;
            width: 70px;
            height: 70px;
            border: 2px solid white;
        }

    }
    span{
        border-left: 1px solid rgb(247, 0, 0);
        padding: 8px;
    }
   
    li {
        padding: 5px;
        list-style: none;
        border-bottom: 2px solid white;
        i {
          
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            font-size: 20px;
           color: rgb(255, 255, 255);
            background-color: rgb(158, 79, 79);
            border-radius: 50%;
            border: 1px solid transparent;
            &:hover {
                background-color: rgb(197, 15, 15);
                border: 1px solid white;
                cursor: pointer;
            }
        }
    }

 
    img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgb(77, 194, 122);
    }
}

.boxDel {
    position: absolute;
    left: 150px;
    width: 150px;
    border-radius: 20px;
    padding: 10px;
    background-color:  rgb(197, 15, 15);
    text-align: center;
   display: grid;
   justify-items: center;
   p{
    padding: 6px;
    margin: 5px 5px 0 5px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: rgb(170, 167, 167);
    color: black;
    &:hover {
        border: 1px solid black;
        background-color: rgb(238, 234, 234);
        cursor: pointer;
    }
   }
}

.choixDel {
    display: flex;
}