/////////////////// communication //////////////////

.communication {
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: 40px;
  
    margin-bottom: 50px;
    border-radius: 15px;
   // background: rgba(252, 251, 251, 0.8);
    background: linear-gradient(105deg,rgba(114, 33, 33, 0.8),rgba(212, 71, 71, 0.8), rgba(156, 120, 120, 0.8) );
    color: white;
    box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
    margin-left: 8%;
    margin-right: 8%;
}

/////////////////// journees d'études //////////////////

#journeeEtudes, #chroniques, #achatsMusee, #museeVirtuel,#nosParticipations   {
    .important {
        margin-top: 120px;
        text-align: center;
        padding: 20px;
        font-size: 30px;
        margin-bottom: 50px;
        border-radius: 15px;
      //  background: rgba(252, 251, 251, 0.8);
        background: linear-gradient(105deg,rgba(114, 33, 33, 0.8),rgba(212, 71, 71, 0.8), rgba(156, 120, 120, 0.8) );
        color: white;
        box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
        margin-left: 8%;
        margin-right: 8%;

    }
}


@media screen and (max-width: 1050px) {
    .activityContain{
        margin: 0;
   
        ul{
            margin: 0;
            max-width: 1050px;
            width: 100%;
           
            li{
                border-radius: 0;
                margin: 0;
            }
        }
        h2.important{
            margin: 0;
            border-radius: 0;
        }
    }
    #journeeEtudes, #chroniques, #achatsMusee, #museeVirtuel,#nosParticipations {
        h2.important{
            margin: 0;
            border-radius: 0;
        }
    }
 
}




    
