.fond {
    position: fixed;
    top: 0;
    z-index: -1;
    height: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    img {
        object-fit: cover;
       height: 100%;
      width: 2000px;
       
    }
   
}


.darkFond {
    position: fixed;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: rgb(12, 12, 12);
    h2 {
        color: white;
    }

    a {
        color: white;
    }
}