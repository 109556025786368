#formSignup{
    position: absolute;
    padding-bottom: 20px;
    border: 1px solid white;
    left: 0;
    top:88px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: linear-gradient(105deg,rgba(34, 33, 114, 1),rgba(71, 104, 212, 1), rgba(120, 125, 156, 1) );
    width: 50%;
    z-index: 999;
    justify-items: center;
    align-items: start;
    .id-password, .name-firstname, .email-tel, .adresse{
        display: grid;
       
        input{
            width: 80%;
            padding: 5px;
            margin-top: 15px;
            border-radius: 5px;
          
        }
       
    }
    #signup-btn{
        margin-top: 15px;
        margin-left: 20px;
        width: 150px;
        padding: 8px;
        border-radius: 5px;
        cursor: pointer;
       border: 1px solid transparent;
       transition: 0.3s;
      
        &:hover{
            color: white;
            transition: 0.3s;
            background-color: rgb(147, 196, 128);
            border: 1px solid rgb(97, 97, 97);
        }
    }
}

#formLogin{
    position: absolute;
    left: 0;
    top:88px;
    height: 80px;
}

#formChange{
    position: absolute;
    left: 0;
    top:90px;
    height: 200px;
}

.formLoginShow {
    animation: 1s showForm forwards;
}

.formLoginStart {
    opacity: 0;
    transform: scale(0);
}
/*.formLoginStart {
    opacity: 1;
    transform: scale(1);
}*/

.formLoginHide {
  
    animation: 1s hideForm forwards;
}


@keyframes hideForm {
    0% {
        transform: scaleY(1);
        transform-origin: top;
    }
    100% {
        transform: scaleY(0);
        transform-origin: top;
    }
}



@keyframes showForm {
    0% {
        transform: scaleY(0);
        transform-origin: top;
    }
    100% {
        transform: scaleY(1);
        transform-origin: top;
    }
}
 #formLogin, #formChange {
    width: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(105deg,rgba(34, 33, 114, 1),rgba(71, 104, 212, 1), rgba(120, 125, 156, 1) );
    justify-content: center;
    align-items: center;
    color: white;

    span {
        position: relative;
        left: 200px;
        &:hover {
            cursor: pointer;
        }
    }
    
    label {
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px;
    }
    input {
        background-color: white;
        border-radius: 10px;
        padding: 5px;
    }
    
}


#loginBtn {
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
   // margin-top: 10px;
   margin-left: 30px;
    padding: 10px;
    border: 1px solid white;
    border-radius: 10px;
    width: 100px;
    color: white;
    background: rgb(1, 128, 179);
    transition:  0.4s;
    box-shadow: 4px 4px 2px 2px rgba(116, 116, 116, 0.2);
    &:hover {     
         background: rgb(0, 160, 223); 
         box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.2);
    } 
}
@media screen and (max-width: 1480px) {
#formSignup {
    i{
        font-size: 14px;
    }
    label {
        font-size: 12px;
       // width: 320px;
    }
}

}

@media screen and (max-width: 1050px) {

    #formLogin{
        font-size: 12px;
        top: 200px;
        height: 178px;
        display: flex;
        flex-direction: column;
        top: 90px;
        z-index: 999;
        label {
            width: 260px;
            margin-bottom: 15px;
        }
        input {
            width: 150px;
        }
    }
    #formSignup{
        width: 100%;
        top: 400px;
      
    }
}