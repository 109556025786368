.adhesion {
    display: flex;
    flex-direction: column;
   margin-top: 100px;
    padding: 20px;
    animation: 1.5s showCard forwards ease-in-out;
    margin-left: 8%;
    margin-right: 8%;
   // margin: auto;
    border-radius: 15px;
  //  background: linear-gradient(105deg,rgba(114, 33, 33, 0.8),rgba(212, 71, 71, 0.8), rgba(156, 120, 120, 0.8) );
  background: rgba(252, 251, 251, 0.8);
  box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
    h2 {
        text-align: center;
        font-size:32px;
        padding: 10px;
        border-bottom: 2px solid white;
        margin-bottom: 20px;
    }
    h3 {
        width: 90%;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
    }
    p{
        text-align: center;
        font-size: 22px;
        margin-bottom: 20px;
    }
    h4 {
        text-align: center;
        font-size: 22px;
        padding: 20px;
    }
    a{
        text-align: center;
    }
 
  
}

.form-adhesion{
    form {
       display: flex;
        flex-direction: column;
        align-items: center;
   
      //  background:   rgba(231, 233, 236, 0.8);
       // box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.2);
        padding: 25px;
        border-radius: 15px;
    }
    input {
        padding: 15px;
        border-radius: 10px;
    }
    .infos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
     justify-content: center;
      margin-bottom: 10px;
    }
}

.bon {
    display: flex;
    justify-content: center;
    margin-top: 20px;
   
    a {
        text-decoration: none;
        font-size: 25px;
        border: 1px solid green;
        padding: 5px; 
        border-radius: 10px;
        color: green;
      
        background-color: rgb(216, 213, 213);
        &:hover {
            background-color: white;
        }
    }
}



@media screen and (max-width: 880px) {
    .adhesion {
        margin: 0;
        padding-bottom: 50px;
        border-radius: 0;
        border-top: 2px solid red;
    }
}