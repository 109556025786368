#pageMembre{
    width: 100%;
}
.avatar {
    position: fixed;
    top: 30px;
    right: 100px;
    display: grid;
    z-index: 9;
    width: 220px;
    height: 100px;
    justify-items: center;
  
    .avatarArrow {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        cursor: pointer;
        &:hover {
            #arrow {
                opacity: 1;
                bottom: 0px;
                color: rgb(250, 250, 250);
            }
        }
        #arrow {
            opacity: 0;
            position: relative;
            bottom: 33px;
            z-index: 15;
            font-size: 20px;
            padding: 0;
            transition: 0.5s;
        }
    }
   
  img {
    object-fit: cover;
    object-position: top;
    z-index: 10;
    border: 2px solid white;
    border-radius: 50%;
    width: 110px;
    height: 110px;
   // padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgba(91, 91, 94, 0.8);
    &:hover {
        background-color: rgba(134, 134, 134, 0.8);      
       }
  }
  
   }
   #bonjour {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0 0 0;
    border: 2px solid white;
    background: rgba(207, 205, 205,0.95);
    border-radius: 20px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
    p {
        display: flex;
        justify-content: center;
        font-size: 18px;
        width: 200px;
        margin: 2px;
       // padding: 5px 20px 0 20px;
        span{
          position: relative;
          left: 8px;
           }
    } 
  
   
}

.actionAvatar {
    display: flex;
    align-items: center;
}

.traitNavCo {
    width: 100%;
    border: 1px solid white;
    margin: 5px 0 5px 0;
}


#menu-membre{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        padding: 8px;
        width: 120px;
        margin: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    i{  
       
        padding: 5px;
        text-align: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-left: 20px;
        font-size: 36px;
        color: rgb(0, 116, 131);
    }
    #cmd-wait{
        position: relative;
        bottom: 10px;
        right: 15px;
        font-size: 16px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 116, 131);
        padding: 5px;
        width: 12px;
        height: 12px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
    }
}

#membreContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 30px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
 
   *::-webkit-scrollbar {
    width: 6px;
    
  }
  *::-webkit-scrollbar-track {
   
  //  background: orange;
    border-radius: 5px;
  //  border: 5px solid RGB(108, 133, 211);
  }

  
  *::-webkit-scrollbar-thumb {
    background-color: rgb(27, 45, 148);
    border-radius: 5px;
   // border: 3px solid RGB(108, 133, 211);
  }

   .cardMembre {
        border: 2px solid white;
        border-radius: 5px;
        width: 100%;
        height: 600px;
       //display: grid;
      
        h3 {
            text-align: center;
            color: white;
            padding: 20px;
            border-bottom: 2px solid white;
        }
   }
}
@media screen and (max-width: 1480px) {
    #bonjour {
        top: 180px;
    }
}

@media screen and (max-width: 1050px) {
    #bonjour {
        position: static;
        margin: 20px;
    }
    #membreContent {
        grid-template-columns: 1fr;
        margin-bottom: 50px;
    }
    .headerTwo{
        span{
            font-size: 30px;
        }
    }
    #showAllpdf{
        border: none;
        padding: 0;
        width: 100%;
        
        #allpdf{
            width: 100%;
            margin: 0;
           padding: 0;
           a, p{
            padding-right: 0;
            padding-left: 0;
           }
         
        }
       
    }
}



.box-cmd-pdf {
    position: absolute;
    margin: auto;
    left: 25%;
    width: 50%;
    background-color: rgb(28, 81, 105);
    border-radius: 8px;
    border: 5px solid rgb(252, 113, 0);
    padding: 20px;
    color: rgb(255, 255, 255);

  button{
    padding: 8px;
   // width: 120px;
}
}
#validationPaiementPDF{
    border: 1px solid rgb(1, 194, 1);
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    margin-right: 5px;
    border-radius: 5px;
    &:hover{
        border: 1px solid rgb(66, 236, 66);
        background-color: rgb(192, 240, 180);
    }

  
}

.yesno-cmd-pdf {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    .yes {
        border: 1px solid rgb(1, 194, 1);
        cursor: pointer;
        background-color: rgb(255, 255, 255);
        margin-right: 5px;
       width: 100px;
       border-radius: 5px;
        &:hover{
            border: 1px solid rgb(66, 236, 66);
            background-color: rgb(192, 240, 180);
        }
      }
      .no {
        border: 1px solid rgb(236, 51, 51);
        cursor: pointer;
        margin-left: 5px;
        background-color: rgb(255, 255, 255);
        width: 100px;
        border-radius: 5px;
        &:hover{
            border: 1px solid rgb(243, 102, 102);
            background-color: rgb(245, 192, 192);
        }
      }
      #refusPaiementPDF {
        border: 1px solid rgb(236, 51, 51);
        cursor: pointer;
        margin-left: 5px;
        background-color: rgb(255, 255, 255);
        border-radius: 5px;
        &:hover{
            border: 1px solid rgb(243, 102, 102);
            background-color: rgb(243, 179, 179);
        }
    }
 
}