* {
    padding: 0;
    margin: 0;  
    font-family: "Josefin Sans", sans-serif; 
}



h1 {
    text-align: center;
 
}




#welcome {
    border-bottom: 2px solid red;
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
    animation: 1.5s welcome forwards;
}

#welcomeHome {
    border-bottom: 2px solid blue;
    margin-bottom: 50px;
    animation: 1.5s welcome forwards;
}

@keyframes welcome {
    0% {
        width: 0;
    }
    100% {
        width: 20%;
    }
}



